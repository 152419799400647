import {SET_PRODUCT, SET_PACKAGE, SET_PRODUCTS, SET_VARIANTS, SET_PAGE_PRODUCT_TYPE} from "./mutations.type";

const state = {
    product: null,
    package: null,
    products: [],
    variants: [],
    page_product_type: 'group',
};

const getters = {
    product(state) {
        return state.product;
    },
    package(state) {
        return state.package;
    },
    products(state) {
        return state.products;
    },
    variants(state) {
        return state.variants;
    },
    page_product_type(state) {
        return state.page_product_type;
    }
};

const mutations = {
    [SET_PRODUCT](state, payload) {
        state.product = payload;
    },
    [SET_PACKAGE](state, payload) {
        state.package = payload;
    },
    [SET_PRODUCTS](state, payload) {
        state.products = payload;
    },
    [SET_VARIANTS](state, payload) {
        state.variants = payload;
    },
    [SET_PAGE_PRODUCT_TYPE](state, payload) {
        state.page_product_type = payload;
    },
};

const actions = {
    //
};

export default {
    state,
    actions,
    mutations,
    getters
};
