import ApiService from '~/services/api.service';

import {
	SET_ERROR,
	SET_CHECKOUT_NAVIGATION_STEP,
	SET_CHECKOUT_QUANTITY,
	SET_CHECKOUT_INSTALLMENT,
	SET_CHECKOUT_SUMMARY,
	SET_CHECKOUT_ADDRESS,
} from "./mutations.type";

import {
	GET_CHECKOUT_QUANTITY,
	GET_CHECKOUT_SUMMARY,
	STORE_CHECKOUT_ADD_TO_BASKET,
	DESTROY_CHECKOUT_ADD_TO_BASKET,
	TRUNCATE_CHECKOUT_ADD_TO_BASKET,
	STORE_CHECKOUT_ADDRESS,
	GET_CHECKOUT_COUPON,
	DESTROY_CHECKOUT_COUPON,
} from "./actions.type";

const state = {
	step : 1,
	quantity : 0,
	installment : null,
	summary : null,
};

const getters = {
	step(state) {
		return state.step;
	},

	quantity(state) {
        return state.quantity;
	},

	installment(state) {
		return state.installment;
	},

	summary(state) {
		return state.summary;
	}
};

const mutations = {
	[SET_CHECKOUT_NAVIGATION_STEP](state, payload) {

	    if (payload === 1) {
            location.hash = 'summary';
        }

        if (payload === 2) {
            location.hash = 'address';
        }

        if (payload === 3) {
            location.hash = 'payment';
        }

        if (payload === 4) {
            location.hash = 'success';
        }

		state.step = payload;
	},

	[SET_CHECKOUT_QUANTITY](state, payload) {
		state.quantity = payload;
        localStorage.setItem('quantity', payload)
	},

	[SET_CHECKOUT_INSTALLMENT](state, payload) {
		state.installment = payload;
	},

	[SET_CHECKOUT_SUMMARY](state, payload) {
		state.summary = payload;
	},

	[SET_CHECKOUT_ADDRESS](state, payload) {
		state.summary.delivery_address_id = payload.delivery_address_id;
		state.summary.invoice_address_id = payload.invoice_address_id;
	},
};

const actions = {
	[GET_CHECKOUT_QUANTITY](context) {
		return new Promise(resolve => {

            let quantity = localStorage.getItem('quantity');

            if (!quantity) {
                quantity = 0;
            }

            resolve({
                data : quantity
            });

            context.commit(SET_CHECKOUT_QUANTITY, quantity);
		});
	},

	[GET_CHECKOUT_SUMMARY](context) {
		return new Promise(resolve => {
            ApiService.init();
			ApiService.get('checkout/summary?coupon_id=' + localStorage.getItem('coupon') + '&installment=' + encodeURIComponent( JSON.stringify(context.state.installment) ))
				.then(({data}) => {
					resolve(data);
					context.commit(SET_CHECKOUT_SUMMARY, data.data);
					context.commit(SET_CHECKOUT_QUANTITY, data.data.quantity ? data.data.quantity : 0);

                    if (data.data) {
                        let analyticsData = [];

                        _.forEach(data.data.products, (product) => {
                            analyticsData.push(product.analytics_data);
                        });

                        window.Analytics.Checkout.Items = analyticsData;
                    }
				});
		});
	},

	[STORE_CHECKOUT_ADD_TO_BASKET](context, products) {
		return new Promise((resolve, reject) => {
            ApiService.init();
			ApiService.store('checkout/add-to-basket', {products : products})
				.then(({data}) => {
					resolve(data.data);
                    window.Analytics.Checkout.Add(data.data.analytics_data);
				})
                .catch(error => {
                    console.log(error)

                    context.commit(SET_ERROR, error.response.data);
                    reject(error.response);
                });
		});
	},

	[DESTROY_CHECKOUT_ADD_TO_BASKET](context, id) {
		return new Promise(resolve => {
            ApiService.init();
			ApiService.destroy('checkout/add-to-basket?id=' + id)
				.then(({data}) => {
					resolve(data);
                    window.Analytics.Checkout.Remove(data.data.analytics_data)
				});
		});
	},

	[TRUNCATE_CHECKOUT_ADD_TO_BASKET](context) {
		return new Promise(resolve => {
            ApiService.init();
			ApiService.destroy('checkout/truncate')
				.then(({data}) => {
					resolve(data);
				});
		});
	},

	[STORE_CHECKOUT_ADDRESS](context, params) {
		return new Promise((resolve, reject) => {
            ApiService.setApi();
			ApiService.store('platform/student/checkout/addresses', params)
				.then(({data}) => {
					resolve(data.data);
					context.commit(SET_CHECKOUT_ADDRESS, data.data);
				})
                .catch(error => {
                    context.commit(SET_ERROR, error.response.data);
                    reject(error.response);
                });
		});
	},

	[GET_CHECKOUT_COUPON](context, code) {
		return new Promise((resolve, reject) => {
            ApiService.setApi();
			ApiService.get('platform/student/checkout/coupons/' + code)
				.then(({data}) => {
					resolve(data);
				})
                .catch(error => {
                    context.commit(SET_ERROR, error.response.data);
                    reject(error.response);
                });
		});
	},

	[DESTROY_CHECKOUT_COUPON](context) {
		return new Promise((resolve, reject) => {
            ApiService.setApi();
			ApiService.destroy('platform/student/checkout/coupons')
				.then(({data}) => {
					resolve(data);
				})
                .catch(error => {
                    context.commit(SET_ERROR, error.response.data);
                    reject(error.response);
                });
		});
	},
};

export default {
	state,
	actions,
	mutations,
	getters
};
