<template>
    <div v-loading="pageLoading">
        <div class="suggestion-box" v-if="elementSize(items)" @click="dialogVisible = true" :class="{active : total}">
            <div class="text">
                <template v-if="!total">
                    {{ type === 'physical' ? 'Dijital ürünlerden seç' : 'Basılı ürünlerden seç' }}
                </template>
                <template v-if="total">
                    {{ count }}x Ürün Seçildi + {{ $n(total, 'currency') }}
                </template>
            </div>
            <div class="action">
                <icon source="plus" v-if="!total" />
                <icon source="check" v-if="total" />
            </div>
        </div>

        <el-dialog :title="type === 'physical' ? 'Dijital ürünlerden seç' : 'Basılı ürünlerden seç'" :visible.sync="dialogVisible" width="80%" append-to-body class="suggestion-dialog">
            <div class="products">
                <template v-for="(item, index) in items">
                    <div class="item" :key="index">
                        <div class="content">
                            <div class="media">
                                <svg-icon :source="findProductIcon(item.type)" />
                            </div>
                            <div class="text">
                                <div class="title">{{ item.title }}</div>
                                <div class="metas">
                                    <template v-for="feature in item.features">
                                        <template v-for="(featureItem, featureItemIndex) in feature.items">
                                            <el-tooltip :content="featureItem.title">
                                                <div class="meta" :key="featureItemIndex">
                                                    <img :src="featureItem.image" alt="feature">
                                                    {{ featureItem.description }}
                                                </div>
                                            </el-tooltip>
                                        </template>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div class="action">
                            <el-input-number v-model="item.card.quantity" :step="1" :min="0" class="product-quantity"
                                             @change="calculate" />
                        </div>
                        <div class="price">
                            <amount :amount="item.card.amount" :quantity="item.card.quantity" />
                        </div>
                    </div>
                </template>
            </div>

            <div slot="footer" class="dialog-footer">
                <div class="total">
                    <div class="title">Eklenecek Tutar</div>
                    <div class="price">
                        {{ $n(total, 'currency') }}
                    </div>
                </div>
                <div class="btn">
                    <el-button class="large secondary" @click="store">
                        Ürünleri Seç
                    </el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'AddToSuggestion',
    props: ['type', 'contentId', 'callback'],
    data() {
        return {
            path: 'content-suggestions',
            pageLoading: false,
            items: [],
            dialogVisible: false,
            total: 0,
            count: 0,
        }
    },
    mounted() {
        this.index();
    },
    methods: {
        index() {
            this.items = window.suggestionSales['content_' + this.contentId];
            this.calculate();
        },

        store() {
            this.dialogVisible = false;
            this.calculate();

            let products = [];

            _.forEach(this.items, (item) => {
                let quantity = parseInt(item.card.quantity);

                if (quantity) {
                    products.push({
                        content_id: item.card.content_id,
                        product_id: item.card.product_id,
                        quantity: item.card.quantity,
                    })
                }
            });

            this.callback(products);
        },

        calculate() {
            this.total = 0;
            this.count = 0;

            let products = [];

            _.forEach(this.items, (item) => {

                let quantity = parseInt(item.card.quantity);

                this.total += quantity * parseFloat(item.card.amount.price);

                if (quantity) {
                    this.count += quantity;

                    products.push({
                        content_id: item.card.content_id,
                        product_id: item.card.product_id,
                        quantity: item.card.quantity,
                    })
                }
            });

            this.callback(products);
        }
    }
}
</script>
