import Vue from 'vue'
import Icon from "./Icon";
import SvgIcon from "./SvgIcon";
import Empty from "./Empty";
import EmptyText from "./EmptyText";
import Avatar from "./Avatar";
import Zones from "./Zones";
import PagePagination from "./PagePagination";
import SelectBox from "./SelectBox";
import Amount from "./Amount";
import AddToBasket from "./AddToBasket";
import AddToSuggestion from "./AddToSuggestion";
import RecaptchaDialog from "./RecaptchaDialog";

Vue.component('icon', Icon);
Vue.component('svg-icon', SvgIcon);
Vue.component('empty', Empty);
Vue.component('empty-text', EmptyText);
Vue.component('avatar', Avatar);
Vue.component('zones', Zones);
Vue.component('page-pagination', PagePagination);
Vue.component('select-box', SelectBox);
Vue.component('amount', Amount);
Vue.component('add-to-basket', AddToBasket);
Vue.component('add-to-suggestion', AddToSuggestion);
Vue.component('recaptcha-dialog', RecaptchaDialog);
