let names = (window.location.hostname).split('.');
let tenant = names[names.length - 2];
let domain = window.location.protocol + '//' + tenant + '.' + names[names.length - 1];

export const config = {
    apiEndPoint: process.env.NODE_ENV === 'development' ? 'https://tisuite.test/api/v1' : 'https://tisuite.co/api/v1',
    tenantName: tenant,
    endPoint: domain,
    domain: domain,
}

export default config;
