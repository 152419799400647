<template>
    <svg class="icon">
        <use :xlink:href="validate()"></use>
    </svg>
</template>

<script>
export default {
    name: 'SvgIcon',
    props: ['source'],
    methods: {
        validate() {

            if (!this.source) {
                return null;
            }

            let source = this.source.replace('icon-', '');

            return '/assets/themes/pan2/img/icons.svg#icon-' + source;
        }
    }
};
</script>
