import ApiService from '~/services/api.service';
import JwtService from '~/services/jwt.service';
import FormResponseService from '~/services/form_response.service';

import {GET_ACCOUNT, UPDATE_ACCOUNT, CHANGE_AVATAR_ACCOUNT} from "./actions.type";
import {SET_ERROR, SET_PRELOADING, SET_ACCOUNT} from "./mutations.type";

const state = {
    account: window.account ? window.account : null
};

const getters = {
    account(state) {
        return state.account;
    }
};

const mutations = {
    [SET_ACCOUNT](state, payload) {
        state.account = payload;
    },
};

const actions = {
    [GET_ACCOUNT](context) {
        if (context.state.account) {
            return false;
        }

        ApiService.setApi();

        return new Promise((resolve, reject) => {
            ApiService.get('account/me')
                .then(({data}) => {
                    context.commit(SET_ACCOUNT, data.data);
                    resolve(data);

                    setTimeout(() => {
                        context.commit(SET_PRELOADING, false);
                    }, 500)
                })
                .catch(error => {
                    JwtService.destroyToken();
                    context.commit(SET_ACCOUNT, null);
                    reject(error);
                });
        });
    },

    [UPDATE_ACCOUNT](context, params) {

        ApiService.setApi();

        return new Promise((resolve, reject) => {
            ApiService.update('platform/account/me/1', params)
                .then(({data}) => {
                    context.commit(SET_ACCOUNT, data.data);
                    resolve(data);

                    FormResponseService.success(data.message)
                })
                .catch(error => {
                    context.commit(SET_ERROR, error.response.data);
                    reject(error.response);
                });
        });
    },

    [CHANGE_AVATAR_ACCOUNT](context, params) {

        ApiService.setApi();

        return new Promise((resolve, reject) => {

            let headers = {
                'Content-Type': 'multipart/form-data'
            }

            ApiService.store('platform/account/me', params, {headers: headers})
                .then(({data}) => {
                    context.commit(SET_ACCOUNT, data.data);
                    resolve(data);
                    FormResponseService.success(data.message)
                })
                .catch(error => {
                    context.commit(SET_ERROR, error.response.data);
                    reject(error.response);
                });
        });
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
