import Vue from 'vue'
import VueI18n from 'vue-i18n'

window._ = require('lodash');
window.axios = require('axios');
window.$ = window.jQuery = require('jquery');

// 3. party
import Vuelidate from 'vuelidate'
import ElementUI from 'element-ui';
import ElementUILocale from 'element-ui/lib/locale'
import ElementUILang from 'element-ui/lib/locale/lang/tr-TR'
import VueTheMask from 'vue-the-mask'
import VueYoutube from 'vue-youtube'

import ApiService from "~/services/api.service";
import store from '~/store'
import {Languages} from '~/resources/lang';
import '~/components'
import '~/App'

// Set
ApiService.init();
ElementUILocale.use(ElementUILang);
Vue.use(VueI18n);
Vue.use(ElementUI, {ElementUILocale});
Vue.use(Vuelidate);
Vue.use(VueTheMask);
Vue.use(VueYoutube);

Vue.config.performance = true;
Vue.config.productionTip = false;

//
import {SET_UI_CONTRACT} from "~/store/mutations.type";

Vue.mixin({
    data: () => {
        return {
            recaptcha : '6LcIzyUeAAAAABGfjplNjkfu0QOGwQRMZ4Ha4taQ',
            setting : window.appSettings,
            datePickerOption: {
                firstDayOfWeek: 1,
            },
            confirmOption: {
                confirmButtonText: 'Onayla',
                cancelButtonText: 'Iptal',
                type: 'warning'
            },
            predefineColors: [
                '#ff4500',
                '#ff8c00',
                '#ffd700',
                '#90ee90',
                '#00ced1',
                '#1e90ff',
                '#c71585',
                '#ffffff',
                '#5c50b9',
            ],
            genders: [
                {
                    id: 'e',
                    title: 'Erkek'
                },
                {
                    id: 'k',
                    title: 'Kadin'
                }
            ]
        }
    },
    methods: {
        cdn_link(path) {
            return 'https://cdn.rhpozitif.com/' + window.appSettings.tenant.storage_path + '/' + path;
        },

        elementSize(items) {
            return _.size(items)
        },

        cloneObjectData(data) {
            return JSON.parse(JSON.stringify(data));
        },

        storePath(payload) {
            let paths = payload.split('/')

            return paths.join('_');
        },

        contractDialog(id) {
            this.$store.commit(SET_UI_CONTRACT, {visible : true, id : id});
        },

        cardMonths() {
            return [
                {
                    id: '01',
                    title: '01'
                },
                {
                    id: '02',
                    title: '02'
                },
                {
                    id: '03',
                    title: '03'
                },
                {
                    id: '04',
                    title: '04'
                },
                {
                    id: '05',
                    title: '05'
                },
                {
                    id: '06',
                    title: '06'
                },
                {
                    id: '07',
                    title: '07'
                },
                {
                    id: '08',
                    title: '08'
                },
                {
                    id: '09',
                    title: '09'
                },
                {
                    id: '10',
                    title: '10'
                },
                {
                    id: '11',
                    title: '11'
                },
                {
                    id: '12',
                    title: '12'
                }
            ]
        },

        cardYears() {
            let items = [];

            let today = (new Date).getFullYear();

            let i;
            for (i = 0; i < 20; i++) {
                items.push({
                    id: today + i,
                    title: today + i,
                })
            }

            return items;
        },

        cardInstallments() {
            let items = [];

            items.push({
                id: 1,
                title: this.$t('inputs.single_installment')
            })

            let i = 1;

            for (i = 1; i < 12; i++) {
                items.push({
                    id: parseInt(i + 1),
                    title: (i + 1) + ' ' + this.$t('inputs.installment')
                })
            }

            return items;
        },

        findProductIcon(type) {
            return 'product-type-' + type;
        },

        findProductType(type) {
            switch (type) {
                case 'weekend':
                    return 'Hafta Sonu';
                case 'weekdays':
                    return 'Hafta İçi';
                case 'night':
                    return 'Gece';
                case 'day':
                    return 'Gündüz';
                case 'live_and_record':
                    return 'Canlı ve Kayıttan';
                case 'live':
                    return 'Canlı';
                case 'record':
                    return 'Kayıttan';
                default :
                    return type;
            }
        },
    }
});

new Vue({
    store,
    i18n: new VueI18n({
        locale: 'tr-TR',
        silentTranslationWarn: true,
        messages: Languages,
        dateTimeFormats: {
            'tr-TR': {
                short: {
                    year: 'numeric', month: 'short', day: 'numeric'
                },
                long: {
                    year: 'numeric', month: 'short', day: 'numeric',
                    weekday: 'short', hour: 'numeric', minute: 'numeric'
                }
            }
        },
        numberFormats: {
            'en-Us': {
                currency: {
                    style: 'currency', currency: 'TRY', symbol: '₺'
                }
            },
            'en': {
                currency: {
                    style: 'currency', currency: 'TRY', symbol: '₺'
                }
            },
            'tr-TR': {
                currency: {
                    style: 'currency', currency: 'TRY', symbol: '₺'
                }
            },
            'tr': {
                currency: {
                    style: 'currency', currency: 'TRY', symbol: '₺'
                }
            }
        }
    }),
    replace: false,
}).$mount('#app');
