<template>
    <div class="avatar" :class="{text : !source, image : source}">
        <template v-if="source">
            <img :src="findSource()" :alt="name">
        </template>
        <template v-if="!source">
            {{ findName() }}
        </template>
    </div>
</template>

<script>
export default {
    name: 'Avatar',
    props: {
        name : {
            default : 'ALESKursum'
        },
        source : {
            default : null
        }
    },
    methods : {
        findName() {

            if (typeof(this.name) === 'number') {
                return this.name;
            }

            return (this.name).slice(0, 1);
        },

        findSource() {
            if ((this.source).slice(0, 4) === 'http') {
                return this.source;
            }

            return this.source;
        }
    }
};
</script>
