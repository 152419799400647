import ApiService from '~/services/api.service';
import JwtService from '~/services/jwt.service';
import FormResponseService from '~/services/form_response.service';

import {
    STORE_LOGIN,
    STORE_REGISTER,
    STORE_FORGOT_PASSWORD,
    STORE_RESET_PASSWORD,
    GET_CHECKOUT_QUANTITY
} from "./actions.type";

import {
    SET_ERROR,
    SET_AUTH_DIALOG,
    SET_AUTH_DIALOG_TAB,
    SET_AUTH_TOKEN,
    DESTROY_AUTH_TOKEN,
    SET_ACCOUNT
} from "./mutations.type";

const state = {
    dialog : false,
    dialogTab : 'login'
};

const getters = {
    dialog(state) {
        return state.dialog;
    },
    dialogTab(state) {
        return state.dialogTab;
    }
};

const mutations = {
    [SET_AUTH_DIALOG](state, payload) {
        state.dialog = payload
    },
    [SET_AUTH_DIALOG_TAB](state, payload) {
        state.dialogTab = payload
    },
    [SET_AUTH_TOKEN](state, payload) {
        window.appSettings.accessToken = payload.access_token;
        JwtService.storeToken(payload.access_token);
        JwtService.getToken();

        if (payload.is_redirect) {
            window.location = '/'
        }
    },
    [DESTROY_AUTH_TOKEN]() {
        JwtService.destroyToken();
    },
};

const actions = {
    [STORE_LOGIN](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.store('auth/login', payload)
                .then(({data}) => {
                    data.data.is_redirect = payload.isRedirect
                    context.commit(SET_AUTH_TOKEN, data.data);
                    context.commit(SET_ACCOUNT, data.user);
                    context.dispatch(GET_CHECKOUT_QUANTITY);
                    resolve(data);
                })
                .catch(error => {
                    context.commit(SET_ERROR, error.response.data);
                    reject(error.response);
                });
        });
    },
    [STORE_REGISTER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.store('auth/register', payload)
                .then(({data}) => {
                    data.data.is_redirect = payload.isRedirect
                    context.commit(SET_AUTH_TOKEN, data.data);
                    context.commit(SET_ACCOUNT, data.user);
                    context.dispatch(GET_CHECKOUT_QUANTITY);
                    resolve(data);
                })
                .catch(error => {
                    context.commit(SET_ERROR, error.response.data);
                    reject(error.response);
                });
        });
    },
    [STORE_FORGOT_PASSWORD](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.store('auth/password/forgot', payload)
                .then(({data}) => {
                    resolve(data);

                    FormResponseService.success(data.message)
                })
                .catch(error => {
                    context.commit(SET_ERROR, error.response.data);
                    reject(error.response);
                });
        });
    },
    [STORE_RESET_PASSWORD](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.store('auth/password/reset', payload)
                .then(({data}) => {
                    resolve(data.data);

                    FormResponseService.success(data.message)
                })
                .catch(error => {
                    context.commit(SET_ERROR, error.response.data);
                    reject(error.response);
                });
        });
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
