import Vue from 'vue'

// misc
Vue.component('script-misc-contract', () => import('./src/Misc/Contract'));
Vue.component('script-misc-we-call-you', () => import('./src/Misc/WeCallYou'));
Vue.component('script-misc-account', () => import('./src/Misc/Account'));
Vue.component('script-misc-checkout', () => import('./src/Misc/Checkout'));
Vue.component('script-misc-campaign-countdown', () => import('./src/Misc/CampaignCountdown'));
Vue.component('script-misc-contact', () => import('./src/Misc/Contact'));
Vue.component('script-misc-feedback', () => import('./src/Misc/Feedback'));
Vue.component('script-misc-faq', () => import('./src/Misc/Faq'));
Vue.component('script-misc-comment', () => import('./src/Misc/Comment'));
Vue.component('script-misc-download', () => import('./src/Misc/Download'));
Vue.component('script-misc-job', () => import('./src/Misc/Job'));
Vue.component('script-misc-earthquake', () => import('./src/Misc/Earthquake'));
Vue.component('script-misc-video-button', () => import('./src/Misc/VideoButton'));
Vue.component('script-misc-promo-dialog', () => import('./src/Misc/PromotionDialog'));

// auth
Vue.component('script-auth-dialog', () => import('./src/Auth/Dialog'));
Vue.component('script-auth-login', () => import('./src/Auth/Login'));
Vue.component('script-auth-register', () => import('./src/Auth/Register'));
Vue.component('script-auth-forgot-password', () => import('./src/Auth/ForgotPassword'));
Vue.component('script-auth-reset-password', () => import('./src/Auth/ResetPassword'));
Vue.component('script-auth-verify', () => import('./src/Auth/Verify'));
Vue.component('script-auth-verify-validation', () => import('./src/Auth/VerifyValidation'));

// account
Vue.component('script-account', () => import('./src/Account/Index'));

// cms
Vue.component('script-cms-exam-calendar', () => import('./src/Cms/ExamCalendar'));
Vue.component('script-cms-leveling-exam', () => import('./src/Cms/LevelingExam'));

// catalog
Vue.component('script-catalog-preview', () => import('./src/Catalog/Preview'));
Vue.component('script-catalog-product', () => import('./src/Catalog/Product'));
Vue.component('script-catalog-product-variant', () => import('./src/Catalog/Variant'));
Vue.component('script-catalog-product-property', () => import('./src/Catalog/Property'));
Vue.component('script-catalog-webinar', () => import('./src/Catalog/Webinar'));
Vue.component('script-catalog-webinar-countdown', () => import('./src/Catalog/Countdown'));

// checkout
Vue.component('script-checkout', () => import('./src/Checkout/Index'));
