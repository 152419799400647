import axios from "axios";
import JwtService from "~/services/jwt.service";
import Config from "~/config/app.js";

const ApiService = {
    init() {
        axios.defaults.baseURL = Config.endPoint;

        ApiService.setHeader();
    },

    setApi() {
        axios.defaults.baseURL = Config.apiEndPoint;

        ApiService.setHeader();
    },

    setHeader() {
       axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
       axios.defaults.headers.common['X-CSRF-TOKEN'] =  window.appSettings.csrfToken;
       axios.defaults.headers.common['Tenant'] = Config.tenantName;
       axios.defaults.headers.common['Accept'] = 'application/json';
       axios.defaults.headers.common['Authorization'] = 'Bearer ' + JwtService.getToken();
    },

    query(resource, params) {
        return axios.get(resource, params);
    },

    get(resource, params = {}) {
        return axios.get(resource, params);
    },

    store(resource, params = {}, headers = {}) {
        return axios.post(resource, params, headers);
    },

    update(resource, params = {}) {
        return axios.put(resource, params);
    },

    destroy(resource, params = {}) {
        return axios.delete(resource, params);
    }
};

export default ApiService;
