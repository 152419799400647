import Vue from "vue";
import Vuex from "vuex";
import FormResponseService from '~/services/form_response.service';
import Config from "~/config/app";

// Init
Vue.use(Vuex);

// Global process
import {SET_ERROR, SET_PRELOADING, SET_UI_CONTRACT} from "./mutations.type";

// Modules
import request from "./request.module";
import account from "./account.module";
import auth from "./auth.module";
import catalog from "./catalog.module";
import checkout from "./checkout.module";

export default new Vuex.Store({
    namespaced: true,
    state: {
        config : Config,
        errors: null,
        preloading : true,
        contract : {
            visible : false,
            id : null,
        }
    },
    mutations : {
        [SET_ERROR](state, error) {
            state.errors = error;
            FormResponseService.error(error)
        },
        [SET_PRELOADING](state, payload) {
            state.preloading = payload;
        },
        [SET_UI_CONTRACT](state, payload) {
            state.contract = payload;
        },
    },
    getters : {
        contract(state) {
            return state.contract;
        },
    },
	modules: {
        request,
        auth,
        account,
        catalog,
        checkout,
	}
});
