<template>
    <div class="product-basket-capsule">
        <div class="bottom">
            <div class="column left" v-if="type !== 'event'">
                <add-to-suggestion :type="type" :contentId="request.content_id" :callback="suggestionInit"/>
            </div>
            <div class="column right">
                <div class="el-button tertiary large fluid" @click="store" v-loading="loading" :disabled="loading">
                    {{ $t('add_to_basket') }}
                </div>
            </div>
        </div>

        <el-dialog :visible.sync="successDialogVisible" append-to-body class="add-to-basket-dialog" :class="{step1 : step === 1, step2 : step === 2}">
            <div class="dialog-global-close-btn" @click="successDialogVisible = false">
                <i class="el-icon-close"></i>
            </div>

            <template v-if="response">
                <div class="step step-1" v-if="step === 1">
                    <div class="step-wrp">
                        <div class="media">
                            <div class="check-container">
                                <div class="check-background">
                                    <svg viewBox="0 0 65 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7 25L27.3077 44L58.5 7" stroke="white" stroke-width="13" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="text">
                            Ürün sepete eklendi!
                        </div>
                    </div>
                </div>
                <div class="step step-2" v-if="step === 2">
                    <div class="product-wrp">
                        <div class="products">
                            <template v-for="(product, index) in response.products">
                                <div class="product" :key="index">
                                    <div class="content">
                                        <div class="media">
                                            <svg-icon :source="findProductIcon(product.type)" />
                                        </div>
                                        <div class="text">
                                            <div class="title">{{ product.title }}</div>
                                            <div class="description">
                                                <template v-if="product.variant_type === 'live_and_record'">
                                                    <span>Canlı</span> ve Kayıttan
                                                </template>
                                                <template v-if="product.variant_type === 'record'">
                                                    Sadece <span>Kayıttan</span>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div class="actions">
                            <div>
                                <a href="/checkout" class="el-button large tertiary fluid">
                                    Sepete git
                                </a>
                            </div>
                            <div>
                                <button type="button" class="el-button large secondary fluid mt-20" @click="successDialogVisible = false">
                                    Alışverişe devam et
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="suggestions" v-if="elementSize(response.suggestions)">
                        <div class="suggestion-title">
                            <span>Ders Notlarınızı</span> Unutmayın!
                        </div>
                        <div class="shop-page">
                            <div class="product-rows">
                                <el-row :gutter="24">
                                    <template v-for="(item, index) in response.suggestions">
                                        <el-col :xs="24" :sm="24" :md="12" :lg="8" :key="index">
                                            <div class="product-row">
                                                <div class="product-row-content">
                                                    <div class="media">
                                                        <img :src="item.image" alt="cover">
                                                        <div class="badges" v-if="elementSize(item.badges)">
                                                            <template v-for="(badge, badgeIndex) in item.badges">
                                                                <div class="badge" :style="badge.style" :key="badgeIndex">
                                                                    {{ badge.title }}
                                                                </div>
                                                            </template>
                                                        </div>

                                                        <div class="caption">
                                                            <div class="teachers">
                                                                <template v-for="(teacher, teacherIndex) in item.teachers">
                                                                    <el-tooltip :content="teacher.name" placement="bottom">
                                                                        <div class="item" :key="teacherIndex">
                                                                            <avatar :name="teacher.name" :source="teacher.avatar" />
                                                                        </div>
                                                                    </el-tooltip>
                                                                </template>
                                                            </div>

                                                            <div class="information">
                                                                <div class="metas">
                                                                    <div class="meta">
                                                                        <icon source="message-video" />
                                                                        {{ item.metas.lesson_count }} Ders
                                                                    </div>
                                                                    <div class="meta">
                                                                        <icon source="clock-outline" />
                                                                        {{ item.metas.lesson_count }} Sa.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="title">
                                                        {{ item.title }}
                                                    </div>

                                                    <div class="price-box">
                                                        <div class="price-box-wrp">
                                                            <QuickAddCart :slug="item.slug" />
                                                            <Amount :item="item.amount" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </el-col>
                                    </template>
                                </el-row>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import {STORE_CHECKOUT_ADD_TO_BASKET} from "~/store/actions.type";
import {SET_CHECKOUT_QUANTITY} from "~/store/mutations.type";

export default {
    name: 'AddToBasket',
    props : ['request', 'packages', 'type', 'callback'],
    data() {
        return {
            loading: false,
            successDialogVisible: false,
            errorDialogVisible: false,
            step: 1,

            response : null,
            suggestionProducts : []
        }
    },
    methods : {
        store() {

            let products = [];

            if (this.type === 'package') {
                let child = [];

                _.forEach(this.packages, (content) => {
                    child.push({
                        content_id: content.id,
                        product_id: content.teacher.variant.id,
                        quantity: 1,
                    });
                })

                products.push({
                    content_id: this.request.content_id,
                    product_id: this.request.product_id,
                    quantity: this.request.quantity,
                    child : child
                });

            } else {
                products.push({
                    content_id: this.request.content_id,
                    product_id: this.request.product_id,
                    quantity: this.request.quantity,
                });
            }

            _.forEach(this.suggestionProducts, (item) => {
                products.push({
                    content_id: item.content_id,
                    product_id: item.product_id,
                    quantity: item.quantity,
                })
            });

            this.loading = true;

            this.$store
                .dispatch(STORE_CHECKOUT_ADD_TO_BASKET, products)
                .then((response) => {
                    this.response = response;
                    this.loading = false;
                    this.successDialogVisible = true;
                    this.step = 1;
                    this.$store.commit(SET_CHECKOUT_QUANTITY, response.quantity);
                    localStorage.setItem('total', response.total)

                    setTimeout(() => {
                        this.step = 2
                    }, 2000);
                })
                .catch(() => {
                    this.loading = false;
                });
        },

        suggestionInit(products) {
            this.suggestionProducts = products;

            this.callback(this.elementSize(products))
        }
    }
}
</script>
